<template>
    <nav>
        <div class="logo">
            <router-link to="/">Dung<em>Lu</em></router-link>
        </div>
        <div class="menu-icon">
            <span></span>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "NavComp"
    }
</script>

<style scoped>

</style>