<template>
    <footer>
        <div class="container-fluid">
            <div class="col-md-12">
                <p>Copyright &copy; 2020 DUNGLU

                    | Designed by TemplateMo</p>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterComp"
    }
</script>

<style scoped>

</style>